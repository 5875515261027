<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">{{ villageName }}牲畜防疫信息</div> 
    </div>

    <div class="content_box">
      <div class="content_item_box">
        <!-- 防疫员列表 -->
        <div class="left_box">
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">防疫员列表</div>

            <div class="w_100 h_80 mon_text lh_25 color_fff fs_16 main_color">
              (ᠬᠢᠵᠢᠭ ᠬᠠᠮᠠᠭᠠᠯᠠᠭᠴᠢ᠎ᠶᠢᠨ ᠬᠦᠰᠦᠨᠦᠭ)
            </div>
          </div>

          <div class="w_full flex_row_around mt_20">
            <div class="w_20_p color_fff flex_start text_center flex_center">
              <div class="w_40 ml_30">序号</div> 
              <div class="w_30 mon_text fs_16">
                (ᠳ᠋ᠤᠭᠠᠷ)
              </div>
            </div> 
            <div class="w_20_p flex_start color_fff text_center flex_center">
              <div class="w_40">姓名</div> 
              <div class="w_30 h_30 mon_text fs_16">
                (ᠣᠪᠤᠭ ᠨᠡᠷ᠎ᠡ)
              </div>
            </div> 
            <div class="w_20_p flex_start color_fff text_center flex_center">
              <div class="w_80">防疫数量</div> 
              <div class="w_50 h_30 lh_20 mon_text fs_16">
                (ᠬᠢᠵᠢᠭ ᠰᠡᠷᠭᠡᠢᠯᠡᠬᠦ ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠶ᠎ᠡ)
              </div>
            </div> 
            <div class="w_20_p flex_start color_fff text_center flex_center">
              <div class="w_40 ml_30">电话</div> 
              <div class="w_30 h_50 mon_text fs_16">
                (᠎ᠤᠲᠠᠰᠤ)
              </div>
            </div> 
          </div>

          <div class="list_box">
            <div 
              class="list_item overflow_auto"
              v-for="(item, index) in list1" :key="index"
            >
              <span class="w_20_p font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="w_20_p font_number" v-else>{{ index+1 }}</span> 

              <div 
                class="name w_30_p text_center" 
                :class="{activeColor: activeIndex == index }"
                @click="clickYuZhongYuanName(index)"
              >
              {{ item.name }}
              </div> 

              <div class="w_25_p font_number">{{ item.yu_zhong_num }}</div> 

              <div class="w_30_p text_center font_number">{{ item.phone }}</div> 
            </div>
          </div>
        </div> 

        <!-- 疫苗列表 -->
        <div class="left_box">
          <div class="title flex_start">
            <div class="w_100 main_color p_10 border_box fs_18 fw_bold">疫苗列表</div>

            <div class="w_50 h_80 mon_text lh_25 color_fff fs_16 main_color">
              (ᠸᠠᠺᠼᠢᠨ᠎ᠤ ᠬᠦᠰᠦᠨᠦᠭ)
            </div>
          </div>

          <div class="w_full flex_row_around mt_20">
            <div class="w_25_p flex_start color_fff text_center flex_center">
              <div class="w_40 ml_50">编号</div> 
              <div class="w_30 mon_text fs_16">
                (ᠳ᠋ᠤᠭᠠᠷ)
              </div>
            </div> 
            <div class="w_25_p flex_start color_fff text_center flex_center">
              <div class="w_40 ml_60">名称</div> 
              <div class="w_30 mon_text fs_16">
                (ᠨᠡᠷᠡᠢᠳᠥᠯ)
              </div>
            </div> 
            <div class="w_25_p flex_start color_fff text_center flex_center">
              <div class="w_40 ml_50">存量</div> 
              <div class="w_30 h_50 mon_text fs_16">
                (ᠬᠠᠳᠠᠭᠠᠯᠠᠯᠲᠠ)
              </div>
            </div> 
            <div class="w_25_p flex_start color_fff text_center flex_center">
              <div class="w_40 ml_40">用量</div> 
              <div class="w_30 h_50 mon_text fs_16">
                (ᠬᠡᠷᠡᠭᠯᠡᠬᠦ ᠬᠡᠮᠵᠢᠶ᠎ᠡ)
              </div>
            </div>
          </div>

          <div class="list_box">
            <div  class="list_item" v-for="(item, index) in yiMiaoList" :key="index" >
              <div class="w_25_p text_center font_number">{{ item.num }}</div> 
              <div class="w_25_p text_center " @click="clickYiMiaoMingCheng(index)">{{ item.name }}</div> 
              <div class="w_25_p text_center font_number">{{ item.count }}</div> 
              <div class="w_25_p text_center font_number">{{ item.use_count }}</div> 
            </div>
          </div>
        </div> 
      </div>
      

      <div class="right_box">
        <div class="top_box">
          <div class="map_box">
            <!-- <span class="show_map_btn color_fff" v-show="hideLargeMap" @click="hideLargeMap = false">地图</span>
            
            <ba-lin-you-qi-map v-show="!hideLargeMap" @showVillage="showVillage" />
            <da-ban-map v-if="hideLargeMap && villageName == '大板镇'" />
            <su-bo-ri-ga v-if="hideLargeMap && villageName == '索博日嘎镇'" />
            <xing-fu-zhi-lu v-if="hideLargeMap && villageName == '幸福之路苏木'" />
            <ba-yan-hu-shuo-hamlet v-if="hideLargeMap && villageName == '巴彦琥硕镇'" />
            <cha-gan-mu-lun-hamlet v-if="hideLargeMap && villageName == '查干沐沦苏木'" />
            <mei-dian-hua v-if="hideLargeMap && villageName == '大板煤电化基地'" />

            <ba-yan-ta-la v-if="hideLargeMap && villageName == '巴彦塔拉苏木'" />
            <cha-gan-nuo-er v-if="hideLargeMap && villageName == '查干诺尔镇'" />
            <bao-ri-wu-su v-if="hideLargeMap && villageName == '宝日勿苏镇'" />
            <xi-la-mu-lun v-if="hideLargeMap && villageName == '西拉沐沦苏木'" /> -->

            <!-- <ba-lin-you-qi-map :mapValue="mapValue" /> -->
            <map-com class="map" />

            <div class="full count_box cursor" v-if="showYimiaoCount" @click="showYimiaoCount = false">
              <div class="tool_tip count_1"> {{ yiMiaoCount.value1 }} </div>
              <div class="tool_tip count_2"> {{ yiMiaoCount.value2 }} </div>
              <div class="tool_tip count_3"> {{ yiMiaoCount.value3 }} </div>
              <div class="tool_tip count_4"> {{ yiMiaoCount.value4 }} </div>
              <div class="tool_tip count_5"> {{ yiMiaoCount.value5 }} </div>
              <div class="tool_tip count_6"> {{ yiMiaoCount.value6 }} </div>
              <div class="tool_tip count_7"> {{ yiMiaoCount.value7 }} </div>
              <div class="tool_tip count_8"> {{ yiMiaoCount.value8 }} </div>
              <div class="tool_tip count_9"> {{ yiMiaoCount.value9 }} </div>
              <div class="tool_tip count_10"> {{ yiMiaoCount.value10 }} </div>
            </div>
          </div>

          <div class="user_detail mt_5">
            <div class="title flex_start mb_20">
              <div class="w_120 main_color p_10 border_box fs_18 fw_bold">防疫员信息</div>

              <div class="w_130 h_40 mon_text lh_25 color_fff fs_16 main_color">
                (ᠬᠢᠵᠢᠭ ᠬᠠᠮᠠᠭᠠᠯᠠᠭᠴᠢ᠎ᠶᠢᠨ ᠮᠡᠳᠡᠭᠡ ᠵᠠᠩᠭᠢ)
              </div>
            </div>

            <div class="top_box">
              <div class="avatar_box mt_10">
                <img src="../../../../assets/images/user/user-avatar.svg" alt="">
              </div>
              <div class="user_info_box">
                <div class="item mb_5">
                  姓名：{{ userInfo.name }}
                </div>
                <div class="item mb_5">
                  年龄：{{ userInfo.age || '37' }}
                </div>
                <div class="item mb_5">
                  住址：{{ userInfo.hamlet || '' }}
                </div>
                <div class="item mb_5">
                  电话：{{ userInfo.phone }}
                </div>
                <div class="item mb_5">
                  防疫质量：<span class="main_color">优良</span> 
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="bottom_div mt_10">
          <div class="yu_zhong_yuan_list">
            <div class="title flex_start mb_20">
              <div class="w_100 main_color p_10 border_box fs_18 fw_bold">防疫记录</div>

              <div class="w_130 h_40 mon_text lh_25 color_fff fs_16 main_color">
                (ᠬᠢᠵᠢᠭ ᠰᠡᠷᠭᠡᠢᠯᠡᠬᠰᠡᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ)
              </div>
            </div>

            <div class="list_box">
              <div class="list_item mv_10">
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_40 ml_40">牧户</div> 
                  <div class="w_30 h_50 mon_text fs_16">
                    (ᠮᠠᠯᠴᠢᠨ ᠡᠷᠦᠬᠡ)
                  </div>
                </div> 
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_80 ml_50">疫苗编号</div> 
                  <div class="w_30 h_50 mon_text fs_16">
                    (ᠸᠠᠺᠼᠢᠨ᠎ᠤ ᠳ᠋ᠤᠭᠠᠷᠯᠠᠯ)
                  </div>
                </div> 
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_80 ml_50">疫苗种类</div> 
                  <div class="w_30 h_50 mon_text fs_16">
                    (ᠸᠠᠺᠼᠢᠨ᠎ᠤ ᠲᠥᠷᠦᠯ ᠵᠦᠢᠯ)
                  </div>
                </div> 
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_80 ml_70">所在村镇</div> 
                  <div class="w_30 h_50 mon_text fs_16">
                    (ᠲᠣᠰᠭᠤᠨ ᠪᠠᠯᠭᠠᠰᠤ)
                  </div>
                </div>
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_60 ml_70">耳标号</div> 
                  <div class="w_30 h_50 mon_text fs_16">
                    (ᠴᠢᠬᠢᠨ ᠲᠡᠮᠳᠡᠭ)
                  </div>
                </div>
                <div class="value2 color text_center flex_start flex_center">
                  <div class="w_80 ml_10">防疫日期</div> 
                  <div class="w_30 h_70 mon_text fs_16">
                    (ᠬᠢᠵᠢᠭ ᠰᠡᠷᠭᠡᠢᠯᠡᠬᠰᠡᠨ ᠡᠳᠦᠷ ᠬᠤᠭᠤᠴᠠᠭ᠎ᠠ)
                  </div>
                </div>
              </div>
              
              <div class="list_item" v-for="(item, index) in userInfo.record" :key="index">
                <div class="value3 text_center" @click="clickVillageName(item.name)">{{ item.name }}</div> 
                <div class="value3 text_center font_number">{{ item.num }}</div> 
                <div class="value3 text_center">{{ item.type }}</div> 
                <div class="value1 cursor text_center" @click="changeHighLight(item.village)">{{ item.hamlet }}</div> 
                <div class="value5 text_center font_number">{{ item.ear_num }}</div> 
                <div class="value3 text_center font_number">{{ item.date1 }}</div> 
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'

  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'
  import MapCom from '../../../../components/map/map.vue';

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
      MapCom
    },
    data() {
      return {
        activeIndex: 0,
        villageName: '巴林右旗',
        data1: {},
        hideLargeMap: false,
        showYimiaoCount: true,

        yiMiaoCount: {},

        mapValue: {
          name: ''
        },

        userInfo: {
          name: "乌力吉",
          num: "4148133",
          village: '大板镇',
          hamlet: "大板镇浩饶沁嘎查",
          phone: '185****2345',
          yu_zhong_num: 32,
          ear_num: 1012,
          date1: '2022.8-10',
          date2: '2023-3-21',
          record: [
            {
              name: "宝音",
              num: "41415175",
              type: '口蹄疫',
              village: '大板镇',
              hamlet: "大板镇红旗嘎查",
              phone: '139****5608',
              yu_zhong_num: 43,
              ear_num: 1005,
              pin_pai: '品牌A',
              date1: '2022.2-13',
              date2: '2023-11-15'
            },
            {
              name: "巴特尔",
              num: "41415132",
              type: '布病',
              hamlet: "大板镇苏艾力嘎查",
              phone: '158****6998',
              yu_zhong_num: 89,
              ear_num: 1006,
              pin_pai: '品牌B',
              date1: '2022.3-13',
              date2: '2023-12-15'
            },
            {
              name: "阿音",
              num: "41415132",
              type: '高致病性禽流感',
              hamlet: "大板镇苏艾力嘎查",
              phone: '158****6998',
              yu_zhong_num: 89,
              ear_num: 1006,
              pin_pai: '品牌B',
              date1: '2022.3-13',
              date2: '2023-12-15'
            }
          ]
        },

        list1: [
          {
            name: "乌力吉",
            num: "4148133",
            type: '牛结核病',
            hamlet: "大板镇浩饶沁嘎查",
            phone: '185****2345',
            yu_zhong_num: 32,
            ear_num: 1012,
            date1: '2022.8-10',
            date2: '2023-3-21',
            record: [
              {
                name: "宝音",
                num: "41415175",
                village: '大板镇',
                type: '口蹄疫',
                hamlet: "大板镇红旗嘎查",
                phone: '139****5608',
                yu_zhong_num: 43,
                ear_num: 1005,
                pin_pai: '品牌A',
                date1: '2022.2-13',
                date2: '2023-11-15'
              },
              {
                name: "巴特尔",
                num: "41415132",
                village: '幸福之路苏木',
                type: '布病',
                hamlet: "幸福之路苏木床金嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "阿音",
                num: "41415132",
                village: '索博日嘎镇',
                type: '牛结核病',
                hamlet: "索博日嘎镇常兴村",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              }
            ]
          },
          {
            name: "巴特尔",
            num: "1833",
            hamlet: "大板镇大冷村",
            phone: '157****6545',
            yu_zhong_num: 49,
            ear_num: 1043,
            date1: '2022.5-02',
            date2: '2023-2-05',
            record: [
              {
                name: "满达",
                num: "41432453",
                village: '大板镇',
                hamlet: "大板镇苏红旗嘎查",
                phone: '178****6498',
                yu_zhong_num: 39,
                ear_num: 1007,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
            ]
          },
          {
            name: "牧人",
            num: "41415118",
            hamlet: "大板镇西哈嘎查",
            phone: '159****6798',
            yu_zhong_num: 67,
            ear_num: '0042',
            date1: '2022.5-23',
            date2: '2023-2-28',
            record: [
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
              {
                name: "斯日敖都",
                phone_number: "15344153083",
                num: "238948",
                village: '大板镇',
                hamlet: "大板镇苏胡斯台嘎查",
                phone: '159****2342',
                yu_zhong_num: 54,
                ear_num: 1010,
                pin_pai: '品牌B',
                date1: '2022.4-13',
                date2: '2023-1-15'
              },
            ]
          },
          {
            name: "宝音",
            num: "41415175",
            hamlet: "大板镇红旗嘎查",
            phone: '139****5608',
            yu_zhong_num: 43,
            ear_num: 1005,
            pin_pai: '品牌A',
            date1: '2022.2-13',
            date2: '2023-11-15',
            record: [
              {
                name: "巴特尔",
                num: "41415132",
                village: '大板镇',
                hamlet: "大板镇苏艾力嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "阿音",
                num: "41415132",
                village: '大板镇',
                hamlet: "大板镇苏艾力嘎查",
                phone: '158****6998',
                yu_zhong_num: 89,
                ear_num: 1006,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "满达",
                num: "41432453",
                village: '大板镇',
                hamlet: "大板镇苏红旗嘎查",
                phone: '178****6498',
                yu_zhong_num: 39,
                ear_num: 1007,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "宝音斯琴",
                phone_number: "15849679411",
                num: "17387928",
                village: '大板镇',
                hamlet: "大板镇苏大冷嘎查",
                phone: '176****3248',
                yu_zhong_num: 53,
                ear_num: 1008,
                pin_pai: '品牌B',
                date1: '2022.3-13',
                date2: '2023-12-15'
              },
              {
                name: "德宝",
                phone_number: "13789532215",
                num: "2387928",
                village: '大板镇',
                hamlet: "大板镇苏西哈嘎查",
                phone: '175****8748',
                yu_zhong_num: 34,
                ear_num: 1009,
                pin_pai: '品牌B',
                date1: '2022.6-23',
                date2: '2023-4-25'
              },
            ]
          },
          {
            name: "巴特尔",
            num: "41415132",
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "阿音",
            num: "41415132",
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "满达",
            num: "41432453",
            hamlet: "大板镇苏红旗嘎查",
            phone: '178****6498',
            yu_zhong_num: 39,
            ear_num: 1007,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "宝音斯琴",
            phone_number: "15849679411",
            num: "17387928",
            hamlet: "大板镇苏大冷嘎查",
            phone: '176****3248',
            yu_zhong_num: 53,
            ear_num: 1008,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "德宝",
            phone_number: "13789532215",
            num: "2387928",
            hamlet: "大板镇苏西哈嘎查",
            phone: '175****8748',
            yu_zhong_num: 34,
            ear_num: 1009,
            pin_pai: '品牌B',
            date1: '2022.6-23',
            date2: '2023-4-25'
          },
          {
            name: "斯日敖都",
            phone_number: "15344153083",
            num: "238948",
            hamlet: "大板镇苏胡斯台嘎查",
            phone: '159****2342',
            yu_zhong_num: 54,
            ear_num: 1010,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "乌云参丹",
            phone_number: "13948361412",
            num: "237832",
            hamlet: "大板镇苏胡斯台嘎查",
            phone: '176****5642',
            yu_zhong_num: 56,
            ear_num: 1011,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "那顺白音",
            phone_number: "15004869151",
            num: "894373",
            hamlet: "大板镇苏红旗嘎查",
            phone: '159****4342',
            yu_zhong_num: 14,
            ear_num: 1011,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
          {
            name: "布仁巴亚尔",
            phone_number: "15047600256",
            num: "248892",
            hamlet: "大板镇新立村",
            phone: '156****2772',
            yu_zhong_num: 54,
            ear_num: 1012,
            pin_pai: '品牌B',
            date1: '2022.4-13',
            date2: '2023-1-15'
          },
        ],

        list2: [
          {
            name: "额尔灯毕力格",
            num: "4148133",
            village: '大板镇',
            hamlet: "大板镇浩饶沁嘎查",
            phone: '185****2345',
            yu_zhong_num: 32,
            ear_num: 1012,
            date1: '2022.8-10',
            date2: '2023-3-21'
          },
          {
            name: "吉仁巴雅尔",
            num: "1833",
            village: '大板镇',
            hamlet: "大板镇大冷村",
            phone: '157****6545',
            yu_zhong_num: 49,
            ear_num: 1043,
            date1: '2022.5-02',
            date2: '2023-2-05'
          },
          {
            name: "朱广福",
            num: "41415118",
            village: '大板镇',
            hamlet: "大板镇西哈嘎查",
            phone: '159****6798',
            yu_zhong_num: 67,
            ear_num: '0042',
            date1: '2022.5-23',
            date2: '2023-2-28'
          },
          {
            name: "宝音",
            num: "41415175",
            village: '大板镇',
            hamlet: "大板镇红旗嘎查",
            phone: '139****5608',
            yu_zhong_num: 43,
            ear_num: 1005,
            pin_pai: '品牌A',
            date1: '2022.2-13',
            date2: '2023-11-15'
          },
          {
            name: "巴特尔",
            num: "41415132",
            village: '大板镇',
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "牟宝全",
            num: "41415132",
            village: '大板镇',
            hamlet: "大板镇苏艾力嘎查",
            phone: '158****6998',
            yu_zhong_num: 89,
            ear_num: 1006,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
          {
            name: "哈斯达来",
            num: "41432453",
            village: '大板镇',
            hamlet: "大板镇苏红旗嘎查",
            phone: '178****6498',
            yu_zhong_num: 39,
            ear_num: 1007,
            pin_pai: '品牌B',
            date1: '2022.3-13',
            date2: '2023-12-15'
          },
        ],

        yiMiaoList: [
          {
            num: '189843',
            name: '口蹄疫',
            count: 84359,
            use_count: 37565
          },
          {
            num: '326432',
            name: '疯牛疫',
            count: 58439,
            use_count: 44354
          },
          {
            num: '134355',
            name: '禽流感',
            count: 93483,
            use_count: 64354
          },
          {
            num: '54843',
            name: '布病',
            count: 67843,
            use_count: 52324
          },
          {
            num: '82983',
            name: '牛结核病',
            count: 72435,
            use_count: 63829
          },
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 监听大地图点击
      showVillage(val) {
        this.villageName = val

        this.hideLargeMap = true
      },

      // 点击防疫员名称
      clickYuZhongYuanName(index) {
        this.activeIndex = index
        this.userInfo = this.list1[index]
      },

      // 改变地图高亮
      changeHighLight(val) {
        this.mapValue.name = val
      },

      // 点击 疫苗名称 显示疫苗种植 数据
      clickYiMiaoMingCheng(index) {
        this.showYimiaoCount = true

        if (index == 0) {
          this.yiMiaoCount = {
            value1: 3328,
            value2: 4324,
            value3: 6442,
            value4: 2895,
            value5: 1723,
            value6: 2128,
            value7: 1328,
            value8: 2128,
            value9: 4328,
            value10: 3228,
          }
        } else if (index == 1) {
          this.yiMiaoCount = {
            value1: 1282,
            value2: 2242,
            value3: 8423,
            value4: 1953,
            value5: 3234,
            value6: 4280,
            value7: 5898,
            value8: 2128,
            value9: 3282,
            value10: 5428,
          }
        } else if (index == 2) {
          this.yiMiaoCount = {
            value1: 4262,
            value2: 4332,
            value3: 6533,
            value4: 1233,
            value5: 4354,
            value6: 6680,
            value7: 3298,
            value8: 1328,
            value9: 5482,
            value10: 2428,
          }
        } else if (index == 3) {
          this.yiMiaoCount = {
            value1: 8934,
            value2: 4738,
            value3: 2345,
            value4: 5433,
            value5: 5332,
            value6: 2435,
            value7: 5323,
            value8: 5235,
            value9: 7543,
            value10: 5434,
          }
        } else if (index == 4) {
          this.yiMiaoCount = {
            value1: 3785,
            value2: 4738,
            value3: 9345,
            value4: 4533,
            value5: 7432,
            value6: 5435,
            value7: 4323,
            value8: 3435,
            value9: 4543,
            value10: 2334,
          }
        }

        
      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }

  .left_box {

    .title { 
      width: 100%;
      height: 4.8%;
      position: relative;
      
      span {
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 20px;
      }
    }

    .list_box {
      width: 100%;
      height: 73%;
      overflow: auto;
      margin-top: 0px;

      .list_item {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        padding: 0 10px;
        display: flex;
        gap: 5;
        color: #fff;

        // span { width: 60px; }
        // .name { width: 30%; cursor: pointer; }   
        // .value1 { width: 25%; }
        // .value2 { width: 25%; }
        // .value3 { width: 20%; }
        // .value4 { width: 20%; }
      }
    }
    .list_box::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
      border-radius: 10px;
      background: #2366fc;
    }
  }

  .right_box {
    width: 65%;
    height: 100%;

    .top_box {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .map_box {
        width: 60%;
        height: 100%;
        position: relative;

        .count_box {
          position: absolute;
          top: 0;
          left: 0;

          .tool_tip {
            padding: 0px 3px;
            text-align: center;
            background-color: rgba(#212121, .8);
            border-radius: 5px;
            color: #fff;
            position: absolute;
          }
          .count_1 { top: 22%; left: 40%; }
          .count_2 { top: 38.7%; left: 52.7%; }
          .count_3 { top: 43%; left: 43%; }
          .count_4 { top: 48.9%; left: 11%; }
          .count_5 { top: 57%; left: 53%; }
          .count_6 { top: 61%; left: 18%; }
          .count_7 { top: 64%; left: 67%; }
          .count_8 { bottom: 20%; left: 20%; }
          .count_9 { bottom: 15%; left: 51%; }
          .count_10 { bottom: 22%; left: 77%; }
        }

        .show_map_btn { cursor: pointer; }
      }

      .user_detail {
        width: 40%;
        height: 95%;

        .title { 
          width: 100%;
          height: 10%;
          position: relative;
          
          span {
            font-weight: bold;
            font-size: 18px;
            color: #fff;
            position: absolute;
            bottom: 0;
            right: 20px;
          }
        }

        .top_box {
          width: 100%;
          height: 43%;
          padding: 10px;

          .avatar_box {
            width: 30%;
            height: 100%;

            img { width: 100%; height: 100%; }
          }
          .user_info_box {
            width: 70%;
            height: 100%;
            padding: 0 10px;
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }
 
    .bottom_div {
      width: 100%;
      height: 49.2%;
    
      .yu_zhong_yuan_list {
        width: 100%;
        height: 100%;

        .title { 
          width: 100%;
          height: 9%;
          text-align: center;
          position: relative;

          span {
            font-weight: bold;
            font-size: 18px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .list_box {
          width: 100%;
          height: 91%;
          overflow: auto;
          margin-top: 10px;
          padding: 0 20px;

          .list_item {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            padding: 0 10px;
            display: flex;
            gap: 5;
            color: #fff;

            span { width: 100px; }
            .name { width: 35%; cursor: pointer; }   
            .value1 { width: 30%; }
            .value2 { width: 25%; }
            .value3 { width: 20%; }
            .value4 { width: 15%; }
            .value5 { width: 10%; }
          }
        }
        .list_box::-webkit-scrollbar {
          width: 0px;
          height: 100%;
          background-color: #f5f5f5;
        }

        ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
          border-radius: 10px;
          background: #2366fc;
        }
      }
    }  
  }


  .content_item_box { width: 33.333333% !important; }
  .left_cover_box { 
    

    .title { color: #fff; text-align: center; padding-top: 4px; font-size: 20px; font-weight: 600; }
  }
  .map_cover_box { height: 100% !important; }
  

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>